import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home/HomeView.vue';
import MeberView from '../views/Meber/MeberView.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/login'
  },
  {
    path:'/login',
    name:'login',
    component: ()=> import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path:'/home',
    name:'home',
    component:HomeView,
    children:[{
      path:'/',
      redirect:"homeLayout"
    },
      {
        path:"homeLayout",
        name:'homeLayout',
        component:()=> import(/* webpackChunkName: "homeLayout" */ '../views/Home/components/HomeLayout.vue')
      },
      {
        path:"homeDashboard",
        name:'homeDashboard',
        component:()=> import(/* webpackChunkName: "homeDashboard" */ '../views/Home/components/HomeDashboard.vue'),
        children:[
          {
          path:'member',
          name:'member',
          component:()=> import(/* webpackChunkName: "member" */ '../views/Home/components/dashbord/member.vue'),
        },
        {
          path:'turnover',
          name:'turnover',
          component:()=> import(/* webpackChunkName: "turnover" */ '../views/Home/components/dashbord/turnover.vue'),
        },
        {
          path:'directReport',
          name:'directReport',
          component:()=> import(/* webpackChunkName: "turnover" */ '../views/Home/components/dashbord/directReport.vue'),
        },
        {
            path:'teamReport',
            name:'teamReport',
            component:()=> import(/* webpackChunkName: "turnover" */ '../views/Home/components/dashbord/teamReport.vue'),
        },
        {
          path:'hierarchyReport',
          name:'hierarchyReport',
          component:()=> import(/* webpackChunkName: "turnover" */ '../views/Home/components/dashbord/hierarchyReport.vue'),
        },
        {
          path:"withdrawal",
          name:'withdrawal',
          component:()=> import(/* webpackChunkName: "homeWithdrawal" */ '../views/Home/components/dashbord/withdrawal.vue')
        },{
          path:'winloss',
          name:'winloss',
          component:()=> import(/* webpackChunkName: "winloss" */ '../views/Home/components/dashbord/winloss.vue'),
        },{
          path:'commission',
          name:'commission',
          component:()=> import(/* webpackChunkName: "commission" */ '../views/Home/components/dashbord/turncommission.vue'),
        },{
          path:'revshare',
          name:'revshare',
          component:()=> import(/* webpackChunkName: "revshare" */ '../views/Home/components/dashbord/revshare.vue'),
        },{
          path:'setting',
          name:'setting',
          component:()=> import(/* webpackChunkName: "setting" */ '../views/Home/components/dashbord/setting.vue'),
        },{
          path:'network',
          name:'network',
          component:()=> import(/* webpackChunkName: "revshare" */ '../views/Home/components/dashbord/network.vue'),
        },]
      }
    ]

  },
  {
    path:'/about',
    name:'about',
    component: ()=> import(/* webpackChunkName: "about" */ '../views/About/about.vue'),
    children:[{
      path:'/',
      redirect:"aboutLayout"
    },
      {
        path:"aboutLayout",
        name:'aboutLayout',
        component:()=> import(/* webpackChunkName: "aboutLayout" */ '../views/About/components/AboutLayout.vue')
      },
      {
        path:"aboutDashboard",
        name:'aboutDashboard',
        component:()=> import(/* webpackChunkName: "aboutDashboard" */ '../views/About/components/AboutDashboard.vue')
      },
      {
        path:"aboutTurnOver",
        name:'aboutTurnOver',
        component:()=> import(/* webpackChunkName: "aboutTurnOver" */ '../views/About/components/AboutTurnOver.vue')
      },
      {
        path:"aboutContent",
        name:'aboutContent',
        component:()=> import(/* webpackChunkName: "aboutContent" */ '../views/About/components/AboutContent.vue')
      }
    ]
  },
  {
    path:'/meber',
    name:'meber',
    component:MeberView,
    children:[{
      path:'/',
      redirect:"MeberLayout"
    },
      {
        path:"meberLayout",
        name:'meberLayout',
        component:()=> import(/* webpackChunkName: "homeLayout" */ '../views/Meber/components/MeberLayout.vue')
      },
      {
        path:"meberDashboard",
        name:'meberDashboard',
        component:()=> import(/* webpackChunkName: "homeDashboard" */ '../views/Meber/components/MeberDashboard.vue'),
        children:[
          {
            path:'member',
            name:'member',
            component:()=> import(/* webpackChunkName: "member" */ '../views/Meber/components/dashbord/member.vue'),
          },
          {
            path:'turnover',
            name:'turnover',
            component:()=> import(/* webpackChunkName: "turnover" */ '../views/Meber/components/dashbord/turnover.vue'),
          },{
            path:"withdrawal",
            name:'withdrawal',
            component:()=> import(/* webpackChunkName: "homeWithdrawal" */ '../views/Meber/components/dashbord/withdrawal.vue')
          },{
            path:'winloss',
            name:'winloss',
            component:()=> import(/* webpackChunkName: "winloss" */ '../views/Meber/components/dashbord/winloss.vue'),
          },{
            path:'commission',
            name:'commission',
            component:()=> import(/* webpackChunkName: "commission" */ '../views/Meber/components/dashbord/turncommission.vue'),
          },{
            path:'revshare',
            name:'revshare',
            component:()=> import(/* webpackChunkName: "revshare" */ '../views/Meber/components/dashbord/revshare.vue'),
          },{
            path:'setting',
            name:'setting',
            component:()=> import(/* webpackChunkName: "setting" */ '../views/Meber/components/dashbord/setting.vue'),
          },{
            path:'network',
            name:'network',
            component:()=> import(/* webpackChunkName: "revshare" */ '../views/Meber/components/dashbord/network.vue'),
          },]
      }
    ]

  },
]

const router = new VueRouter({
  routes
})

export default router
