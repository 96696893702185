export default {
    namespaced: true,
    state: {
        flag:true,
        token:null
    },
    getters: {
        getToken(state){
            return state.token;
        }
    },
    mutations: {
        toggle(state){
            state.flag = !state.flag;
        },
        setToken(state, b) {
            state.token = b;
            console.log('login success token', b)
        },
    },
    actions: {
    },
    modules: {
    }
}
