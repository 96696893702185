isMobile();
function isMobile() {
  let flag =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
    if(flag){
      window.location.href="http://sgm.hiso33as.com"
    }else{
      console.log('pc端');
      console.log(window.location);
      
    }

}