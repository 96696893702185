<template>
  <div class="home">
    <!-- 顶部导航 -->
    <div class="home-nav font-20 color-3">
      <ul class="home-nav-left flex-x">
        <li class="pointer"><router-link to="/home/homeDashboard/member">DASHBOARD</router-link></li>
        <!--<li class="pointer"><router-link to="/home/homeDashboard/turnover">INSIGHTS REPORT</router-link></li>-->
        <li class="pointer has-child-list">
          <router-link to="/home/homeDashboard/turnover?title=DIRECT DOWNLINE SALES REPORT">INSIGHTS REPORT</router-link>
          <ul>
            <li v-for="(item, index) in childMenu" :style="{ color: activeKey == index ? '#c89a3b' : '' }" :key="item.id"
              @click="tohrefs(item, index)">{{ item.title }}</li>
          </ul>
        </li>
        <li class="pointer"><router-link to="/home/homeDashboard/withdrawal">WITHDRAWAL</router-link></li>
        <!--<li class="pointer"><router-link to="/home/homeDashboard/winloss">WIN/LOSS REPORT</router-link></li>-->
      </ul>
      <!--      <ul class="home-nav-right flex-x">
        <img src="../../assets/icon-elements/FB.png" alt="">
        <img src="../../assets/icon-elements/twitter.png" alt="">
        <img src="../../assets/icon-elements/icon-11.png" alt="">
        <div class="home-login-btn font-bold pointer"><router-link to="/login">LOGIN</router-link></div>
      </ul>-->
      <ul class="home-nav-right flex-x color-3">
        <!--<li class="pointer"><router-link to="/home/homeDashboard/revshare">REVSHARE REPORT</router-link></li>-->
        <li class="pointer"><router-link to="/home/homeDashboard/network">NETWORK TREE</router-link> </li>
        <li class="pointer"><router-link to="/home/homeDashboard/setting">SETTINGS</router-link> </li>
        <div style="display: flex;">
          <span style="margin-top:5px;">User：{{ users.name }}</span>
          <div class="home-login-btn font-bold pointer"><router-link to="/login">LOGOUT</router-link></div>
        </div>
      </ul>
    </div>
    <!-- 首页logo展示  @click="tolayout"-->
    <div class="home-logo">
      <span></span>
    </div>
    <div class="home-content">
      <router-view></router-view>
    </div>
    <!--    <div class="home-footer font-20 color-3 font-bold">COPYRIGHT ©</div>-->
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue';
import { loginInfo } from "@/api/login";
export default {
  name: 'HomeView',
  data() {
    return {
      childMenu: [{
        id: 1,
        title: 'DIRECT DOWNLINE TURNOVER REPORT'
      },
      {
        id: 2,
        title: 'DIRECT DOWNLINE COMMISSION REPORT'
      },
      {
        id: 3,
        title: 'TEAM TURNOVER REPORT'
      },
      {
        id: 4,
        title: 'TEAM COMMISSION REPORT'
      }],
      activeKey: null,
      users: {
        name: "UserName"
      }
    }
  },
  components: {
    HelloWorld
  },
  methods: {
    tohrefs(value, index) {
      this.activeKey = index;
      if (index === 0) {
        this.$router.replace({ path: '/home/homeDashboard/turnover' });
      } else if (index === 1) {
        this.$router.replace({ path: '/home/homeDashboard/directReport' });
      } else if (index === 2) {
        this.$router.replace({ path: '/home/homeDashboard/hierarchyReport' });
      } else if (index === 3) {
        this.$router.replace({ path: '/home/homeDashboard/teamReport' });
      }
    },
    tolayout() {
      this.$router.push('/about/AboutContent')
    },
  },
  created() {
    loginInfo().then(res => {
      if (res.data != null) {
        this.users = res.data;
      }
    });
  }
}
</script>

<style lang="less" scoped>
.home {
  position: relative;
  min-height: 100vh;

  // overflow-x: hidden;
  .home-logo {
    width: 4.9rem;
    height: 1.4rem;
    background-image: url("../../assets/homeLogo.gif");
    background-size: auto 100%;
    background-repeat: no-repeat;
    position: fixed;
    background-position:top;
    // top: 0.1rem;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10002;
    text-align: center;
//     span{
//       width: 4.9rem;
//       height: 2.8rem;
//       border: 3px solid #caab68;
//       display: block;
//      z-index: -10;
//      position: absolute;
//       margin: 0 auto;
//       // margin-top: -1rem;
//       border-radius: 0 0 200px 200px;
//       border-top: 0;
// bottom: 0;
//     }
    
  }

  .home-nav {
    width: 100%;
    height: 0.83rem;
    background-image: url("../../assets/icon-elements/home-nav.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // padding:0 0.91rem;
    padding: 0 0.4rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    font-size: 0.16rem;

    .home-nav-left {
      width: 33%;
      height: 0.83rem;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
    }

    .home-nav-right {
      align-items: center;
      width: 35%;
      height: 0.83rem;
      justify-content: space-between;
      font-weight: bold;

      &>img {
        width: 0.5rem;
        height: 0.5rem;
      }

      .home-login-btn {
        width: 1.09rem;
        height: 0.33rem;
        line-height: 0.33rem;
        background-image: url("../../assets/icon-elements/login-btn.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;
        margin-left: 0.2rem;
      }
    }

    li {
      list-style: none;

    }
  }

  .home-footer {
    position: absolute;
    left: 50%;
    bottom: -1rem;
    transform: translateX(-50%);
    // padding-bottom:0.2rem;
  }
}

// 路由高亮选中
.router-link-active {
  color: #c89a3b;
}

.has-child-list {
  position: relative;
  height: 0.83rem;
  line-height: 0.83rem;

  ul {
    width: 4.4rem;
    line-height: 30px;
    position: absolute;
    top: 0.83rem;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    height: 1.4rem;
    background-color: #fff;
    padding: 0.1rem;
  }
}

.has-child-list:hover ul {
  display: flex;
}</style>
