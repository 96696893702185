<template>
  <div class="home">
    <!-- 顶部导航 -->
    <div class="home-nav font-20 color-3">
      <ul class="home-nav-left flex-x">
        <li class="pointer"><router-link to="/meber/MeberDashboard/member">DASHBOARD</router-link></li>
        <li class="pointer"><router-link to="/meber/MeberDashboard/turnover">INSIGHTS REPORT</router-link></li>
        <li class="pointer"><router-link to="/meber/MeberDashboard/withdrawal">WITHDRAWAL</router-link></li>
        <li class="pointer"><router-link to="/meber/MeberDashboard/setting">SETTING</router-link> </li>
      </ul>
      <ul class="home-nav-right flex-x">
        <img src="../../assets/icon-elements/FB.png" alt="">
        <img src="../../assets/icon-elements/twitter.png" alt="">
        <img src="../../assets/icon-elements/icon-11.png" alt="">
        <div class="home-login-btn font-bold pointer"><router-link to="/login">LOGIN</router-link></div>
      </ul>
    </div>
    <!-- 首页logo展示  @click="tolayout"-->
    <div class="home-logo"></div>
    <div class="home-content">
      <router-view></router-view>
    </div>


  </div>
</template>

<script>
  // @ is an alias to /src
  import HelloWorld from '@/components/HelloWorld.vue';
  export default {
    name: 'HomeView',
    data(){
      return{
      }

    },
    components: {
      HelloWorld
    },
    methods:{
      tolayout(){
        this.$router.push('//homeLayout')
      }
    }
  }
</script>

<style lang="less" scoped>
  .home{
    position:relative;
    min-height: 100vh;
    // overflow-x: hidden;
    .home-logo{
      width:4.9rem;
      height:1.12rem;
      background-image: url("../../assets/icon-elements/home-logo.gif");
      background-size: 100% 100%;
      background-repeat:no-repeat;
      position:fixed;
      top:0;
      left:50%;
      transform: translateX(-50%);
      z-index: 10002;
    }
    .home-nav{
      width:100%;
      height:0.83rem;
      background-image: url("../../assets/icon-elements/home-nav.png");
      background-repeat:no-repeat;
      background-size:100% 100%;
      padding:0 0.91rem;
      box-sizing:border-box;
      display: flex;
      justify-content: space-between;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      .home-nav-left{
        width:35%;
        height:0.83rem;
        align-items:center;
        justify-content: space-between;
        font-weight: bold;
      }
      .home-nav-right{
        align-items:center;
        width:18%;
        justify-content: space-between;
        &>img{
          width:0.5rem;
          height:0.5rem;
        }

        .home-login-btn{
          width:1.09rem;
          height:0.33rem;
          line-height:0.33rem;
          background-image: url("../../assets/icon-elements/login-btn.png");
          background-size:100% 100%;
          background-repeat:no-repeat;
          text-align:center;
          margin-left:0.2rem;
        }
      }
      li{
        list-style:none;

      }
    }


  }

  .home-content{
    display: flex;
    justify-content: center;
    align-items: center ;
  }


  // 路由高亮选中
  .router-link-active{
    color: #c89a3b;

  }
</style>
