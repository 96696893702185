import request from '@/utils/request'

// 登录方法
export function login(name, psw) {
    const data = {
        name,
        psw,
    }
    return request({
        url: '/api/user/login/in',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

// 登录方法
export function loginInfo() {
    return request({
        url: '/api/user/login/info',
        headers: {
            isToken: true
        },
        method: 'get'
    })
}

