import Vue from 'vue'
import Vuex from 'vuex'
import sd from "./sd";
import createPersistedState from "vuex-persistedstate"

const createPersisted = createPersistedState({
  storage: {
    getItem(key) {
      return window.localStorage.getItem(key);
    },
    setItem(key, value) {
      return window.localStorage.setItem(key, value);
    },
    removeItem(key) {
      return window.localStorage.removeItem(key);
    }
  },
  key: '$hiso-x',
})

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    sd
  },
  plugins: [createPersisted]
})


